.venuePage {
  position: absolute;
  top: 85px;
  padding-bottom: 75px;
  min-height: calc(100vh - 160px);
  width: 100%;

  .headerInfo {
    display: flex;
    padding: 0 20px;
    gap: 20px;
    padding-bottom: 20px;
    align-items: flex-end;

    .headerSideInfo, .headerMainInfo {
      width: 50%;
    }

    .headerSideInfo {
      display: flex;
      flex-direction: row;
      font-size: 16px;

      .locationAddress {
        width: calc((100vw - 80px) / 5);
      }

      p {
        white-space: pre-wrap;
      }

    }

    .headerMainInfo {
      font-size: 16px;

      .location {
        font-weight: bold;
        text-transform: capitalize;
      }

      .dates {
        text-transform: capitalize;
      }
      
      .venueTitle {
        font-size: 36px;
        line-height: 34px;
        margin-top: 10px;
        margin-bottom: 15px;
      }
    }
  }

  .images {
    position: relative;
    .imageContainer {
      width: 100vw;
      height: 38vw;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      overflow-x: scroll;
      gap: 5px;
      margin-bottom: 25px;

      img {
        height: 100%;
        width: auto;
      }
    }

    p {
      font-size: 11px;
      margin-left: 20px;
    }
  }

  .venueDetails {
    margin-top: 30px;
    display: flex;
    padding: 0 20px;
    gap: 20px;

    .sideDetails, .venueDescriptionAndProgramTitle {
      width: 50%;
    }

    .sideDetails {
      display: flex;
      gap: 50px 25px;
      align-self: flex-start;

      .program {
        h2 {
          text-transform: capitalize;
          font-size: 16px;
          margin-bottom: 25px;
        }

        .programList {
          display: flex;
          flex-direction: column;
          gap: 25px;
          max-width: 350px;

          .event {
            white-space: pre-wrap;
            p {
              font-size: 16px;
              margin-bottom: 5px;
            }
            .eventTitle {
              font-size: 16px;
            }
          }
        }
      }

      .participants {
        flex-shrink: 0;
        h2 {
          text-transform: capitalize;
          font-size: 16px;
          margin-bottom: 25px;
        }
        .participantsList {
          list-style: none;
          li {
            line-height: 20px;
          }
          li:hover {
            font-weight: bold;
          }
        }
      }

      .venueLocations {
        list-style: none;
        width: 100%;

        li {
          padding-bottom: 10px;
          margin-top: 10px;
          border-bottom: 1px solid black;

          &:first-child {
            margin-top: 0;
          }
          
          &:last-child {
            border: none;
          }

          a:hover {
            font-weight: bold;
          }

          .venueLocationTitle {
            font-size: 16px;
          }
          .addressAndTime {
            display: flex;
            gap: 15px;
            margin-bottom: 16px;
            word-wrap: break-word;
            .address {
              width: calc((100vw - 80px) / 5);
            }
          }
        }
      }
    }
    .venueDescriptionAndProgramTitle {
      display: flex;
      flex-direction: column;

      .venueDescription {
        white-space: pre-wrap;
        padding-bottom: 30px;
      }
      
      .programTitle {
        border-top: 1px solid black;
        font-size: 16px;
        padding-top: 15px;
      }

    }
  }
  .venueEvents {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-top: 25px;
    
    li {
      border-top: 1px solid black;
      display: flex;
      padding: 15px 20px;
      gap: 20px;

      .venueEventTitle, .venueDateTimeAddress {
        width: 25%;
      }

      .venueEventDescription {
        width: calc(50% - 10px);
        min-width: calc(50% - 10px);
        flex-shrink: 0;
        white-space: pre-wrap;
      }

      .venueEventTitle {
        font-weight: bold;
        padding-right: 10%;
      }

      .venueDateTimeAddress {
        text-transform: capitalize;
      }

      &:last-child {
        border-bottom: 1px solid black;
      }
    }
  }
}


@media (max-width: 768px) {
  .venuePage {
    top: 50px;
    width: 100%;
    overflow-x: hidden;

    .headerInfo {
        flex-direction: column-reverse;
        padding: 0 15px;
        gap: 15px;
        margin-bottom: 15px;
    
        .headerMainInfo, 
        .headerSideInfo {
          width: 100%;
        }

        .headerMainInfo {
          font-size: 16px;

          .venueTitle {
            font-size: 32px;
            // line-height: 24.5px;
            margin-bottom: 10px;
          }
        }

        .headerSideInfo {
          font-size: 12px;
          .locationAddress, p {
            width: 50%;
          }
        }

      }

    .images {
      .imageContainer {
        height: 70vw;
        margin-bottom: 20px;
        justify-content: flex-start;
      }
    }

    .venueDetails {
      flex-direction: column-reverse;
      margin-top: 20px;
      padding: 0 15px;
      gap: 30px;

      .sideDetails,
      .venueDescription {
        width: 100%;
      }

      .sideDetails {
        flex-direction: column-reverse;
        gap: 30px;

        .participants {
          font-size: 16px;

          h2 {
            margin-bottom: 20px;
            font-size: 16px;
          }
        }

        .program {
          h2 {
            margin-bottom: 20px;
            font-size: 16px;
          }
          .programList {
            gap: 30px;
            .event {
              p {
                font-size: 12px;
              }
              .eventTitle {
                font-size: 16px;
              }
            }

          }
        }
        .venueLocations li .addressAndTime {
          p {
            width: 50%;
          }
          .address {
            width: calc(50%);

          }
        }
      }
      .venueDescriptionAndProgramTitle {
        width: 100%;

        .venueDescription {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
    .venueEvents {
      li {
        flex-direction: column;
        padding: 15px 15px;

        .venueEventTitle,
        .venueDateTimeAddress,
        .venueEventDescription {
          width: 100%;
        }
      }
    }
  }
  .footer {
    position: absolute;
    margin-left: 15px;
    bottom: 0;
    width: 100%;
  }
  .mobileProgramTitle {
    font-size: 16px;
    margin-left: 15px;
    margin-top: 50px;
  }

  
}