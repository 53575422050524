//  body {
//    overscroll-behavior: none;
//    touch-action: pan-down;
//  }

.participantsSection {

  position: absolute;
  top: 40px;
  width: calc(100% - 20px);
  height: calc(100% - 40px); 
  
  .participantsContent {
    display: flex;
    gap: 30px;
    flex-direction: column;
    height: 85vh;

    .participantsTitle {
      text-transform: capitalize;
      font-weight: bold;
      font-size: 20px;
      margin-left: 20px;
    }
    
    .participantsNames {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      overflow-x: scroll;
      width: 100%;
      overscroll-behavior-x: auto;
      padding-left: 20px;

      .namesCategory {
        display: flex;
        margin-right: 80px;
        margin-bottom: 50px;
        
        h3 {
          font-size: 17px;
          font-weight: bold;
          margin-right: 25px;
          width: 18px;
        }

        .namesInCategory {
          font-size: 17px;
          width: 300px;
        }
        .namesInCategory a {
          display: block;
        }
      }

      a:hover {
        font-weight: bold;
      }
    }

  }  
}

@media (max-width: 768px) {
  .participantsSection {
    top: 40px;
    height: auto;
    width: calc(100% - 45px);
    padding: 0 15px;

    .participantsContent {
        flex-direction: column;
        height: 100%;
        margin-bottom: 150px;

        .participantsTitle {
          font-size: 15px;
          text-transform: lowercase;
          margin-top: 20px;
          display: none;
        }

        .participantsNames {
          flex-wrap: nowrap;
          font-size: 20px;
          margin-top: 15px;
          margin-left: 0;
          width: 100%;
          
          .namesCategory {
            margin-bottom: 25px;
            text-indent: -15px;
            padding-left: 15px;
            display: flex;
            flex-direction: column;
            width: calc(100% - 15px);
            margin-right: 0;
            h3, .namesInCategory {
              font-size: 20px;
            }
            .namesInCategory {
              width: 100%;
            }
          }
        }
    }
  }
  
}
