.navbar {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: bold;

  .navbarRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 34px;

    .menuButton {
      padding-left: 20px;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: calc((100vw - 80px) / 5 - 20px);
      border-bottom: 1px solid black;
    }

    .menuButton button{
      font-weight: bold;
      text-transform: capitalize;
      font-size: 20px;
      line-height: 23px;
    }

    .languagesButtons {
      display: flex;
      list-style: none;
      height: 100%;

      li {
        margin-right: 25px;
        position: relative;
        height: 28px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end ;
        padding-bottom: 6px;
        border-bottom: 1px solid black;
        width: 6vw;

        button {
          font-weight: bold;
          font-size: 16px;
          height: 16px;
        }

      }
    }
  }

  .menu {

    margin-top: 1px;
    font-size: 20px;
    text-transform: capitalize;
    display: none;
    width: 100%;
    gap: 20px;
    // padding-left: 20px;
    // padding-right: 20px;
    z-index: 2;
    background: #fff;

    li {
      width: 100%;
      height: 33px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid black;
      background-color: #fff;

      &:first-child a{
        margin-left: 20px;
      }
    }
  }

}

@media (max-width: 768px) {
  .navbar {
    position: fixed;
    background: #fff;
    z-index: 3000;
    .navbarRow {
      .menuButton {
          width: 52vw;
          padding-left: 15px;

          button {
            font-size: 20px;
          }
      }

      .languagesButtons {
        display: flex;
        list-style: none;
        height: 100%;
        font-size: 16px;

        li {
          margin-right: 15px;
          width: 7.5vw;
        }
      }
    }

    .menu {
      width: calc(100% - 20px);
      gap: 0;
      flex-direction: column;
      // padding-left: 15px;
      padding-right: 0px;

      li {
        padding-left: 25px;
        width: 100%;
        height: 34px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid black;
        &:first-child {
          padding-left: 25px;
          a {
            margin-left: 0;
          }
        }
      }
    }

    
  }

}