.location {
  width: 100%;
  padding-bottom: 10px;
  .locationInfo {
    // padding-top: 10px;
    border-top: 1px solid black;
  }

  &:last-child {
    border-bottom: 1px solid black;
  }
}

.expandedLocation {
  width: 56vw;
  display: flex;
  gap: 20px;
  padding-bottom: 120px;

  .locationInfo {
    width: 100%;
  }

  .locationSideInfo {
    width: 23vw;
    min-width: 10vw;
    max-width: 350px;
    flex-shrink: 0;

    h4 {
      text-transform: capitalize;
    }

    .locationVenues {
      margin-top: 190px;
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
  }
  &:not(:first-child) {
    .cityLogo {
      display: none;
    }
  }
}
.locationName {
  font-size: 32px;
  height: 150px;
  font-weight: normal;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.locationHoursTitle {
  font-size: 11px;
  margin-top: 10px;
}

.locationAddress, .locationHours {
  font-size: 11px;
  white-space: pre-wrap;
  height: 13px;
}



.locationImage {
  width: 100%;
  height: auto;
  margin-top: 25px;
}

.locationDescription {
  white-space: pre-wrap;
  margin-top: 25px;
}


@media (max-width: 768px) {
  .expandedLocation {
    flex-direction: column;
    width: 100%;
    gap: 30px;

    .locationSideInfo {
      width: 100%;
      .locationVenues {
        margin-top: 25px;
      }
    }
  }
  .locationDescription {
    margin-top: 20px;
  }
}