.participantPage {
  position: absolute;
  top: 70px;
  display: flex;
  padding-left: 20px;
  width: calc(100% - 20px);

  .participantInfo {
    width: 24vw;
    overflow-y: scroll;
    height: calc(100vh - 70px - 30px);
    padding-bottom: 30px;

    .participantName {
      font-size: 20px;
      margin-bottom: 5px;
    }

    .participantBio  {
      font-size: 16px;
      white-space: pre-wrap;
      margin-bottom: 20px;
    }
    .participantImage {
      max-width: 100%;
    }
    .participantLinks {
      margin-top: 15px;
      font-size: 12px;
      display: flex;
      flex-direction: column;
    }
  }

  .participantProjects {
    height: calc(100vh - 70px - 50px);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: calc(100vw - 24vw - 40px);
    gap: 30px;
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .participantPage {
    flex-direction: column;
    padding: 0 0;
    margin-right: 0;
    top: 45px;

    .participantInfo {
      width: 100vw;
      height: auto;
      padding-bottom: 0;
      margin-bottom: 50px;
      font-size: 16px;
      line-height: 21px;

      .participantName, .participantBio, .participantLinks{
        padding: 0 15px;
      }

      .participantName {
        font-size: 20px;
      }

      .participantImage {
        width: 50vw;
      }
    }

    .participantProjects {
      height: auto;
      overflow-y: visible;
      width: calc(100vw - 15px);
      margin-left: 15px;
      padding-bottom: 25px;
    }
  }
}
