:root {
  --doc-height: 100%;
}

.programSection {
  position: absolute;
  top: 34px;
  width: 100%;
}

.citiesList {
  display: flex;
  list-style: none;
  text-transform: capitalize;
  font-size: 20px;
  width: 100%;
  gap: 20px;

  .city {
    &:first-child {
      .cityButton {
        margin-left: 20px;
      }
    }

    width: 100%;
    padding-top: 6px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding-bottom: 4px;
    display: flex;
    align-items: flex-end;

    .cityButton {
      text-align: left;
      word-break: break-word;
      text-transform: capitalize;
      
      &:hover {
        font-weight: bold;
      }
    }

    .activeCityButton {
      font-weight: bold;
      display: flex;
      width: 100%;
      justify-content: space-between;
      &:hover {
        font-weight: normal;
      }

      .closeCity {
        height: 23px;
        width: auto;
        margin-left: 5px;
      }
    }

  }
}

.venuesByMonths {
  top: 70px;
  display: flex;
  gap: 0 20px;
  width: 100%;
  overflow: scroll;
  
  .month {
    flex-shrink: 0;
    width: calc((100% - 20px - 20px * 4)/ 5.5);
    
    
    .monthTitle {
      padding-top: 6px;
      font-size: 20px;
      text-transform: capitalize;
      font-weight: normal;
      border-bottom: 1px solid black;
      padding-bottom: 4px;
      display: flex;
      align-items: flex-end;
    }

    .monthVenues {
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-height: calc(100vh - 115px);
      overflow-y: scroll;
    }

    &:first-child {
      .monthTitle {
        padding-left: 20px;
      }

      .monthVenues {
        padding-left: 20px;
      }
    }

    &:last-child {
      .monthVenues {
        padding-right: 20px;
      }
    }
  }
}

@media (max-width: 1307px) {
  .venuesByMonths {
    .month {
      .monthVenues {
        height: calc(var(--doc-height) - 140px);
      }
    }
  }
}

@media (max-width: 900px) {
  .citiesList {
    font-size: 16px;
  }

  .venuesByMonths .month .monthTitle {
    font-size: 16px;
  }

  .venuesByMonths {
    .month {
      .monthVenues {
        max-height: calc(var(--doc-height) - 126px);
      }
    }
  }
}

@media (max-width: 768px) {
  .closeCity {
    display: none;
  }
  .programSection {
    width: calc(100% - 15px);
    padding-left: 15px;

      .citiesList {
        flex-wrap: wrap;
        gap: 0 10px;
        font-size: 16px;
        padding-right: 15px;
        width: calc(100vw - 30px);

        .city {
          border-top: 0;
          
          &:nth-child(-n+4) {
            width: calc((100vw - 60px) / 4);
          }
          
          &:nth-last-child(-n+4) {
            width: calc((100vw - 60px) / 4);
          }

          .cityButton {
            text-align: left;
          }

          &:first-child {
            .cityButton {
              margin-left: 0px;
            }
          }
        }
      }
    }

  .venuesByMonths {
    overflow: scroll;
    flex-wrap: nowrap;
    .month {
      width: 85vw;
      flex-shrink: 0;

      .monthTitle {
        font-size: 16px;
      }

      .monthVenues {
        padding-top: 10px;
        // height: calc(100vh - 134px);
        max-height: calc(var(--doc-height) - 134px);
      }

      &:first-child {
          .monthTitle {
            padding-left: 0px;
          }
      
          .monthVenues {
            padding-left: 0px;
          }
        }
    }
  }
}

@media (max-width: 457px) {
  .venuesByMonths {
    .month {
      .monthVenues {
        height: calc(100vh - 153px) !important;
        max-height: calc(var(--doc-height) - 153px) !important;
      }
    }
  }
}

@media (max-width: 394px) {
  .programSection .citiesList {
    font-size: 15px;
  }

  .venuesByMonths .month .monthTitle {
    font-size: 15px;
  }

  .venuesByMonths {
    .month {
      .monthVenues {
        max-height: calc(var(--doc-height) - 126px);
      }
    }
  }
}

@media (max-width: 373px) {
  .programSection .citiesList {
    font-size: 14px;
  }

  .venuesByMonths .month .monthTitle {
    font-size: 14px;
  }

  .venuesByMonths {
    .month {
      .monthVenues {
        max-height: calc(var(--doc-height) - 126px);
      }
    }
  }
}

@media (max-width: 353px) {
  .programSection .citiesList {
    font-size: 12px;
  }

  .venuesByMonths .month .monthTitle {
    font-size: 12px;
  }

  .venuesByMonths {
    .month {
      .monthVenues {
        max-height: calc(var(--doc-height) - 126px);
      }
    }
  }
}