.leftWire, .rightWire{
  position: absolute;
  height: 100px;
  width: auto;
  top: calc(50% - 50px);
  cursor: pointer;
}

.leftWire {
  left: 0;
}

.rightWire {
  right: 0;
}

@media (max-width: 768px) {
  .leftWire, .rightWire {
    display: none;
  }
}