.venue {
  font-size: 11px;

  .venueTags {

    font-weight: bold;
    display: flex;
    flex-shrink: 1;
    gap: 0px 10px;
    height: 7vw;
    max-height: 35px;

    div {
      flex-shrink: 1;
      flex-grow: 0;
      height: 100%;
      max-height: 35px;
      img {
        height: 100%;
        width: auto;
      }
    }
  }

  .venuePlaceAndTime {
    margin-top: 5px;
    text-transform: capitalize;
    margin-bottom: 4px;
    font-size: 11px;
  }

  .venueTitle {
    font-size: 17px;
    margin-bottom: 8px;
  }
}

@media (max-width: 768px) {
  .venue {
  
      .venueTags {
  
        height: 35px;
      }
    }
}