.aboutSection {
  position: absolute;
  top: 85px;
  padding: 0 20px;
  width: calc(100% - 40px);

  h2, h3 {
    font-size: 16px;
    text-transform: capitalize;
  }


  .descriptions {
    display: flex;
    gap: calc(10vw - 10px);
    margin-bottom: 50px;

    .description {
      width: 40vw;

      .descriptionTitle {
        margin-bottom: 20px;
      }

      .descriptionText {
        width: 100%;
        font-size: 16px;
        white-space: pre-wrap;
      }
    }
  }



  .pressSection {
    margin-bottom: 100px;


    .pressList {
      margin-top: 20px;
      list-style: none;
      width: 100%;

      .pressSource {
        display: flex;
        border-top: 1px solid black;
        padding: 5px 0;
        font-size: 14px;
        width: 100%;

        h4 {
          font-weight: normal;
          width: 50%;
        }
        a {
          margin-left: 20px;
          width: 50%;
          &:hover {
            font-weight: bold;
          }
        }

        &:last-child {
          border-bottom: 1px solid black;
        }
      }
    }
  }

  .partners {
    margin-bottom: 90px;

    .partnersLogos {
      width: 90%;
      margin-top: 20px;
      margin-left: 5%;
    }

  }

  .contacts {
    margin-bottom: 100px;

    h3 {
      margin-bottom: 10px;
    }

    .contactsInfos {
      display: flex;
      margin-top: 10px;
      font-size: 16px;

      .contactsText, .organisersText {
        width: 50vw;

        p {
          width: 35vw;
          white-space: pre-wrap;

          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .aboutSection {
    top: 60px;
    padding: 0 15px;
    width: calc(100% - 30px);

    h2, h3 {
      font-size: 16px;
    }

    .descriptions {
      flex-direction: column;

      .description {
        width: 100%;

        .descriptionText {
           font-size: 16px;
           line-height: 22px;
          }
      }
    }

    .pressSection {
      .pressList {
        .pressSource {
          font-size: 16px;
        }
      }
    }

    .contacts {
      .contactsInfos {
        flex-direction: column;
        

        .contactsText, .organisersText {
          width: 100%;
          font-size: 16px;

          p {
            width: 100%;
          }
        }

        .contactsText {
          margin-bottom: 30px;
        }
      }

    }
  }
}