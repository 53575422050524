hr {
  display: none;
}

.descriptionAndVenue {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding-right: 20px;

  .projectDetails {
    width: 65%;

    .projectTitle {
      font-size: 20px;
      font-weight: normal;
    }

    .projectParameters {
      margin-top: 15px;
      font-size: 11px;
      list-style: none;
    }

    .projectDescription {
      font-size: 16px;
      margin-top: 15px;
      white-space: pre-wrap;
    }
  }

  .venueTag {
    width: 23vw;
    margin-left: 20px;
  }
};
.imageContainer {
  position: relative;
}

.projectImages {
  overflow-x: scroll;
  display: flex;
  flex-wrap: nowrap;

  img {
    height: 450px;
    width: auto;
    margin-right: 5px;
  }
}

.imageSource {
  font-size: 10px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .project {
    &:not(:first-child) {
      hr {
        display: block;
        border: 0.5px solid black;
        margin-left: -15px;
        margin-bottom: 20px;
      }
    }
  }

  .descriptionAndVenue {
    .projectDetails {
        width: 100%;

        .projectTitle {
          font-size: 22px;
        }

        .projectParameters {
          margin-top: 10px;
          font-size: 12px;
        }

        .projectDescription {
          font-size: 16px;
          line-height: 21px;
          margin-top: 10px;
        }
      }
  }

  .projectImages {
    img {
      height: 60vw;
      width: auto;
    }
  }

  .venueTag {
    max-width: 370px;
    margin-top: 20px;
  }
}