.footer {
  // width: 100%;
  position: absolute;
  bottom: 0px;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  list-style: none;
  padding-bottom: 15px;

  li {
    margin-left: 20px;
    width: 105px;
    border-bottom: 1px solid black;
    margin-right: 20px;
    padding-bottom: 5px;
  }
}

@media (max-width: 768px) {
  .footer {
    width: calc(100% - 30px);
    justify-content: center;
    gap: 20px;
    li {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}