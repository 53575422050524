:root {
  --doc-height: 100%;
}

.cities {
  display: flex;
  overflow-x: scroll;
  flex-wrap: nowrap;
  overflow-wrap: normal;
  position: absolute;
  top: 85px;
  padding: 0 20px;
  width: calc(100% - 40px);
  gap: 20px;

  .city {
    height: calc(100vh - 85px);
    overflow-y: scroll;
    width: calc((100vw - 140px) / 4.5);
    min-width: 235px;
    flex-shrink: 0;
    cursor: default;

    .cityNameLogo {
      display: flex;
    }

    .cityName {
      font-size: 32px;
      text-transform: capitalize;
      height: 74px;
      padding-bottom: 15px;
      width: 100%;
    }
    .cityLogoContainer {
      width: 23vw;
      min-width: 10vw;
      max-width: 350px;
      flex-shrink: 0;
      padding-bottom: 15px;
    }
    .cityLogo {
      height: 59px;
    }
    
  }

  .activeCity {
    width: 56vw;

    .locations {
      width: 56vw;
    }
  }


  .locations {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 50px;
  }

  .wire {
    position: fixed;
    bottom: 50px;
  }
  .leftWire {
    left: 0;
  }
  .rightWire {
    right: 0;
  }

}

@media (max-width: 768px) {
  .cities {
    top: 50px;
    gap: 15px;
    padding: 0 15px;
    width: calc(100% - 30px);
    .city {
      // height: calc(100vh - 50px);
      height: calc(var(--doc-height) - 50px);
      width: calc((100vw - 120px) / 1.2);
      .cityLogo {
        display: none;
      }
    }
    .activeCity {
      width: calc((100vw - 80px));

      .locations {
        width: calc(100vw - 80px);
      }
    }
  }
}